import React from 'react';
import { ArrowLeft } from 'lucide-react';

const NotFoundPage = () => {
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col overflow-hidden relative">
            {/* Background Decoration */}
            <div className="absolute -top-40 -right-40 w-96 h-96 bg-purple-200 rounded-full opacity-30 blur-3xl"></div>
            <div className="absolute -bottom-40 -left-40 w-96 h-96 bg-green-200 rounded-full opacity-30 blur-3xl"></div>

            {/* Header */}
            <header className="py-6 px-6 md:px-12">
                <div className="flex items-center">
                    <div className="text-purple-700 font-bold text-2xl">
                        Purpuligo<span className="text-green-500">Tech</span>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main className="flex-grow flex flex-col items-center justify-center px-6 py-12">
                <div className="relative">
                    {/* 404 Background Text */}
                    <div className="absolute -z-10 text-gray-100 text-[12rem] md:text-[20rem] font-bold opacity-60 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 select-none">
                        404
                    </div>

                    {/* Content Card */}
                    <div className="bg-white rounded-2xl shadow-xl p-8 md:p-12 w-full max-w-lg text-center z-10 relative">
                        {/* Glitch Effect Decoration */}
                        <div className="absolute -top-12 -right-12 w-24 h-24 bg-green-400 opacity-20 rounded-full transform rotate-45"></div>
                        <div className="absolute -bottom-12 -left-12 w-24 h-24 bg-purple-400 opacity-20 rounded-full transform rotate-45"></div>

                        {/* Icon */}
                        <div className="mb-6 inline-flex">
                            <div className="w-20 h-20 bg-gradient-to-br from-purple-50 to-purple-100 rounded-full flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-purple-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>

                        {/* Text Content */}
                        <h1 className="text-3xl md:text-4xl font-bold mb-4 text-gray-800">
                            Page Not Found
                        </h1>

                        <p className="text-gray-600 mb-8 max-w-md mx-auto">
                            Oops! It seems the digital pathway you're looking for has gone missing in our system.
                            Let's get you back on track.
                        </p>

                        {/* Buttons */}
                        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                            <button
                                onClick={() => window.history.back()}
                                className="bg-gray-100 text-gray-800 hover:bg-gray-200 font-medium py-3 px-6 rounded-lg flex items-center transition-all duration-300 w-full sm:w-auto justify-center"
                            >
                                <ArrowLeft className="mr-2 h-4 w-4" />
                                Go Back
                            </button>

                            <a
                                href="/"
                                className="bg-gradient-to-r from-purple-600 to-purple-700 text-white font-medium py-3 px-6 rounded-lg hover:shadow-lg transition-all duration-300 w-full sm:w-auto text-center"
                            >
                                Return Home
                            </a>
                        </div>
                    </div>
                </div>

                {/* Additional Help */}
                {/* <div className="mt-12 text-center">
                    <h2 className="text-lg font-medium text-gray-700 mb-4">
                        Need help finding something?
                    </h2>
                    <div className="flex flex-wrap justify-center gap-4">
                        <a href="/services" className="text-purple-700 hover:text-purple-800 transition-colors">
                            Our Services
                        </a>
                        <a href="/contact" className="text-purple-700 hover:text-purple-800 transition-colors">
                            Contact Us
                        </a>
                        <a href="/about" className="text-purple-700 hover:text-purple-800 transition-colors">
                            About Us
                        </a>
                        <a href="/sitemap" className="text-purple-700 hover:text-purple-800 transition-colors">
                            Sitemap
                        </a>
                    </div>
                </div> */}
            </main>

            {/* Animation */}
            <div className="absolute inset-0 pointer-events-none overflow-hidden">
                {[...Array(5)].map((_, i) => (
                    <div
                        key={i}
                        className={`absolute bg-purple-300 opacity-20 rounded-full animate-float-slow`}
                        style={{
                            width: `${Math.random() * 10 + 5}px`,
                            height: `${Math.random() * 10 + 5}px`,
                            top: `${Math.random() * 100}%`,
                            left: `${Math.random() * 100}%`,
                            animationDelay: `${Math.random() * 5}s`,
                            animationDuration: `${Math.random() * 10 + 15}s`,
                        }}
                    ></div>
                ))}
                {[...Array(5)].map((_, i) => (
                    <div
                        key={i + 5}
                        className={`absolute bg-green-300 opacity-20 rounded-full animate-float-slow`}
                        style={{
                            width: `${Math.random() * 10 + 5}px`,
                            height: `${Math.random() * 10 + 5}px`,
                            top: `${Math.random() * 100}%`,
                            left: `${Math.random() * 100}%`,
                            animationDelay: `${Math.random() * 5}s`,
                            animationDuration: `${Math.random() * 10 + 15}s`,
                        }}
                    ></div>
                ))}
            </div>

            {/* Footer */}
            <footer className="py-6 px-6 md:px-12 text-center text-gray-600 text-sm">
                <p>© {new Date().getFullYear()} Purpuligo Technologies. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default NotFoundPage;