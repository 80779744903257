import React from 'react';
import Header from '../common/Header';
import Hero from '../common/Hero';
import Services from '../common/Services';
import About from '../common/About';
import Domains from '../common/Domains';
import CallToAction from '../common/CallToAction';
import Team from '../common/Team';
import Testimonials from '../common/Testimonials';
import Contact from '../common/Contact';
import Footer from '../common/Footer';
import Navigation from '../layout/Navigation';
import ScrollManager from '../layout/scrollManager';

const Home = () => {
  return (
    <ScrollManager>
      <div className="flex flex-col min-h-screen font-sans">
        <Header />
        <Navigation />
        <main>
          <section id="home" className="scroll-snap-section">
            <Hero />
          </section>
          <section id="services" className="scroll-snap-section">
            <Services />
          </section>
          <section id="about" className="scroll-snap-section">
            <About />
          </section>
          <section id="domains" className="scroll-snap-section">
            <Domains />
          </section>
          {/* <section id="team" className="scroll-snap-section">
            <CallToAction />
            <Team />
          </section> */}
          <section id="testimonials" className="scroll-snap-section">
            <Testimonials />
          </section>
          <section id="contact" className="scroll-snap-section">
            <Contact />
          </section>
        </main>
        <Footer />
      </div>
    </ScrollManager>
  );
};

export default Home;