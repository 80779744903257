import React from 'react';
import P1 from '../../assets/images/p1.png';
import P2 from '../../assets/images/p2.png';
import P3 from '../../assets/images/p3.png';

const StarRating = ({ rating = 5 }) => {
    return (
        <div className="flex text-yellow-400">
            {[...Array(rating)].map((_, i) => (
                <svg key={i} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
            ))}
        </div>
    );
};

const TestimonialCard = ({ name, company, testimonial }) => {
    return (
        <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="flex items-center mb-6">
                <div className="mr-4">
                    <img src={P1} alt={name} className="w-16 h-16 rounded-full" />
                </div>
                <div>
                    <h4 className="font-bold text-gray-800">{name}</h4>
                    <p className="text-purple-700">{company}</p>
                </div>
            </div>
            <p className="text-gray-600 mb-4">{testimonial}</p>
            <StarRating />
        </div>
    );
};

const Testimonials = () => {
    const testimonials = [
        {
            name: "Sunita Patel",
            company: "Farmtech Solutions",
            testimonial: "\"Purpuligo Technologies transformed our agricultural business with their farm management system. We've seen a 30% increase in productivity since implementation.\""
        },
        {
            name: "Raj Kumar",
            company: "HealthPlus Clinic",
            testimonial: "\"The patient management system developed by Purpuligo has streamlined our operations. Our staff saves hours each day, allowing us to focus more on patient care.\""
        },
        {
            name: "Ananya Das",
            company: "LogiMove Transport",
            testimonial: "\"Our logistics efficiency has improved dramatically with Purpuligo's tracking system. Real-time updates and route optimization have reduced our operational costs by 25%.\""
        }
    ];

    return (
        <section className="py-16 md:py-24 bg-gray-24">
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">What Our Clients Say</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Hear from businesses that have successfully digitized with our solutions.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            name={testimonial.name}
                            company={testimonial.company}
                            testimonial={testimonial.testimonial}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;