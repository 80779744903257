import React, { useState, useEffect } from 'react';
import { Code, Globe, Activity, Zap, Briefcase, Users, ArrowRight, CheckCircle, ChevronDown, ChevronUp } from 'lucide-react';

const technologies = [
    { name: "React", url: "react.com" },
    { name: "Node.js", url: "nodejs.org" },
    { name: "Python", url: "python.org" },
    { name: "AWS", url: "aws.com" },
    { name: "Azure", url: "azure.microsoft.com" },
    { name: "Google Cloud", url: "cloud.google.com" },
    { name: "MongoDB", url: "mongodb.com" },
    { name: "PostgreSQL", url: "postgresql.org" },
    { name: "Docker", url: "docker.com" },
    { name: "Kubernetes", url: "kubernetes.io" },
    { name: "Angular", url: "angular.io" },
    { name: "Vue.js", url: "vuejs.org" }
];

const ServiceCard = ({ icon, title, description, features, detailedDescription, caseStudy, onSelect, isActive }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = (e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            className={`relative transition-all duration-300 ${isActive ? 'bg-white shadow-xl rounded-lg scale-100 z-10' : 'bg-gray-50 shadow-md rounded-lg scale-95 hover:scale-97 hover:shadow-lg'}`}
            onClick={() => onSelect()}
        >
            {isActive && (
                <div className="absolute -top-2 left-0 right-0 flex justify-center">
                    <span className="px-4 py-1 bg-green-500 text-white text-xs font-medium rounded-full">
                        Popular Choice
                    </span>
                </div>
            )}

            <div className="p-8">
                <div className={`p-4 rounded-full inline-block mb-6 transition-colors ${isActive ? 'bg-purple-600 text-white' : 'bg-purple-100 text-purple-700'}`}>
                    {icon}
                </div>
                <h3 className="text-xl font-bold text-gray-800 mb-4">{title}</h3>
                <p className="text-gray-600 mb-6">{description}</p>

                <div className="space-y-3 mb-6">
                    {features.slice(0, 3).map((feature, index) => (
                        <div key={index} className="flex items-start">
                            <CheckCircle size={18} className={`${isActive ? 'text-green-500' : 'text-purple-500'} mr-2 mt-1 flex-shrink-0`} />
                            <span className="text-gray-700">{feature}</span>
                        </div>
                    ))}

                    {isExpanded && (
                        <>
                            {features.slice(3).map((feature, index) => (
                                <div key={index + 3} className="flex items-start">
                                    <CheckCircle size={18} className={`${isActive ? 'text-green-500' : 'text-purple-500'} mr-2 mt-1 flex-shrink-0`} />
                                    <span className="text-gray-700">{feature}</span>
                                </div>
                            ))}

                            <div className="pt-4 mt-4 border-t border-gray-100">
                                <h4 className="font-bold text-gray-800 mb-3">How We Approach It</h4>
                                <p className="text-gray-600 mb-4">{detailedDescription}</p>

                                {caseStudy && (
                                    <div className="bg-gray-50 p-4 rounded-lg mt-4">
                                        <h5 className="font-bold text-gray-800 mb-2">Client Success Story</h5>
                                        <p className="text-gray-600 mb-3">{caseStudy.description}</p>
                                        <div className="flex items-center">
                                            <div className="bg-white p-2 rounded-full mr-3">
                                                <img src={caseStudy.logo || "/api/placeholder/40/40"} alt={caseStudy.client} className="w-10 h-10 rounded-full" />
                                            </div>
                                            <div>
                                                <p className="font-medium text-gray-800">{caseStudy.client}</p>
                                                <p className="text-sm text-gray-500">{caseStudy.industry}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <div className="flex justify-between items-center">
                    <button
                        onClick={toggleExpand}
                        className="text-purple-700 font-medium inline-flex items-center hover:underline"
                    >
                        {isExpanded ? (
                            <>Show Less <ChevronUp size={16} className="ml-1" /></>
                        ) : (
                            <>Learn More <ChevronDown size={16} className="ml-1" /></>
                        )}
                    </button>

                    <button
                        className={`px-4 py-2 ${isActive ? 'bg-purple-700 text-white' : 'border border-purple-700 text-purple-700'} rounded-full text-sm font-medium hover:bg-purple-700 hover:text-white transition-colors`}
                        onClick={(e) => { e.stopPropagation(); window.location.href = '#contact'; }}
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

const ProcessStep = ({ number, title, description, isActive, onClick }) => {
    return (
        <div
            className={`relative p-6 rounded-lg cursor-pointer transition-all duration-300 ${isActive ? 'bg-purple-700 text-white shadow-lg' : 'bg-white text-gray-800 hover:bg-purple-50'}`}
            onClick={onClick}
        >
            <div className="flex items-start">
                <div className={`flex-shrink-0 w-10 h-10 rounded-full flex items-center justify-center text-lg font-bold ${isActive ? 'bg-white text-purple-700' : 'bg-purple-100 text-purple-700'} mr-4`}>
                    {number}
                </div>
                <div>
                    <h4 className={`text-lg font-bold mb-2 ${isActive ? 'text-white' : 'text-gray-800'}`}>{title}</h4>
                    <p className={isActive ? 'text-purple-100' : 'text-gray-600'}>{description}</p>
                </div>
            </div>
        </div>
    );
};

const IndustryBadge = ({ name, isActive, onClick }) => {
    return (
        <button
            className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${isActive
                ? 'bg-purple-700 text-white'
                : 'bg-white text-gray-700 hover:bg-purple-50'
                }`}
            onClick={onClick}
        >
            {name}
        </button>
    );
};

const Services = () => {
    const [activeService, setActiveService] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [activeIndustry, setActiveIndustry] = useState('All');
    const [isInView, setIsInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                }
            },
            { threshold: 0.1 }
        );

        const section = document.getElementById('services');
        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, []);

    useEffect(() => {
        if (isInView) {
            const interval = setInterval(() => {
                setActiveStep((prev) => (prev + 1) % processSteps.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [isInView]);

    const services = [
        {
            icon: <Code size={32} />,
            title: "Custom Software Development",
            description: "Tailored software solutions designed specifically for your business needs and workflow optimization.",
            features: [
                "Bespoke application development from concept to deployment",
                "Legacy system modernization and migration",
                "Custom API development and integration",
                "Agile development with regular updates and feedback",
                "Cross-platform compatibility",
                "Ongoing maintenance and support",
                "Scalable architecture for future growth"
            ],
            detailedDescription: "Our custom software development process starts with a deep dive into your business processes. We identify inefficiencies, map out workflows, and design a solution that addresses your specific challenges. Using modern technologies and development practices, we create robust, scalable applications that grow with your business.",
            caseStudy: {
                client: "AgriHarvest Solutions",
                industry: "Agriculture",
                description: "We developed a custom inventory and logistics management system that reduced operational costs by 35% and improved delivery times by 40%."
            }
        },
        {
            icon: <Globe size={32} />,
            title: "Web Development",
            description: "Responsive, user-friendly websites and web applications that enhance your online presence and drive engagement.",
            features: [
                "Responsive website design optimized for all devices",
                "Progressive Web App (PWA) development",
                "E-commerce solutions with secure payment integration",
                "Content Management Systems customized to your needs",
                "SEO-friendly architecture and implementation",
                "Web accessibility compliance",
                "Performance optimization for faster loading"
            ],
            detailedDescription: "Our web development approach focuses on creating fast, responsive, and user-friendly experiences. We combine aesthetic design with technical excellence to build websites that not only look great but also perform exceptionally well. From simple informational sites to complex web applications, we deliver solutions that elevate your online presence.",
            caseStudy: {
                client: "MediCare Plus",
                industry: "Healthcare",
                description: "Our redesigned patient portal increased user engagement by 65% and reduced appointment scheduling time from 15 minutes to under 2 minutes."
            }
        },
        {
            icon: <Activity size={32} />,
            title: "Digital Transformation",
            description: "Comprehensive strategies to help businesses transition from traditional processes to digital systems for improved efficiency.",
            features: [
                "Digital strategy consulting and roadmap development",
                "Process automation and workflow optimization",
                "Cloud migration and infrastructure modernization",
                "Digital adoption training and change management",
                "Data digitization and management solutions",
                "IoT integration for real-time monitoring",
                "Digital workplace implementation"
            ],
            detailedDescription: "Digital transformation is more than just implementing new technology—it's about reimagining how your business operates. We work with you to create a comprehensive strategy that addresses your technological needs while considering the human aspects of change. Our approach ensures smooth transitions with minimal disruption to your operations.",
            caseStudy: {
                client: "EastLogistics",
                industry: "Logistics",
                description: "Our digital transformation initiative helped them transition from paper-based processes to a fully digital workflow, reducing processing time by 75% and error rates by 90%."
            }
        },
        {
            icon: <Zap size={32} />,
            title: "IT Consulting",
            description: "Expert advice on technology implementation and strategy to maximize your IT investments and stay competitive.",
            features: [
                "Technology stack assessment and recommendations",
                "IT infrastructure planning and optimization",
                "Cybersecurity assessment and strategy",
                "Cost optimization and ROI analysis for IT investments",
                "Vendor selection and management guidance",
                "IT governance and compliance advisory",
                "Technology roadmapping for future growth"
            ],
            detailedDescription: "Our IT consulting services provide you with expert guidance to navigate complex technology decisions. We assess your current infrastructure, identify areas for improvement, and recommend solutions that align with your business goals. Our vendor-neutral approach ensures you get advice that's truly in your best interest.",
            caseStudy: {
                client: "Bengal Manufacturing",
                industry: "Manufacturing",
                description: "Our IT strategy helped them save ₹25 lakhs annually on technology costs while improving system reliability and security."
            }
        },
        {
            icon: <Briefcase size={32} />,
            title: "Enterprise Solutions",
            description: "Scalable enterprise applications that streamline operations, improve data insights, and boost overall productivity.",
            features: [
                "ERP system implementation and customization",
                "CRM solutions tailored to your business processes",
                "Business intelligence and analytics platforms",
                "Supply chain management and logistics systems",
                "Human resource management systems",
                "Financial management and accounting software",
                "Enterprise application integration (EAI)"
            ],
            detailedDescription: "Our enterprise solutions approach focuses on integrating powerful systems that work seamlessly together. Whether you need ERP, CRM, or specialized industry software, we implement solutions that connect your people, processes, and data. We emphasize user adoption with comprehensive training and support to ensure your team embraces the new technologies.",
            caseStudy: {
                client: "Eastern Retail Group",
                industry: "Retail",
                description: "Our integrated ERP implementation consolidated 5 disparate systems, providing real-time inventory visibility across 12 locations and increasing operational efficiency by 45%."
            }
        },
        {
            icon: <Users size={32} />,
            title: "IT Training & Support",
            description: "Comprehensive training and ongoing technical support to ensure smooth system operation and maximize user adoption.",
            features: [
                "User training for new system implementations",
                "Technical documentation and knowledge transfer",
                "24/7 technical support and maintenance services",
                "System performance monitoring and optimization",
                "Help desk services and ticketing systems",
                "Regular system updates and security patching",
                "Customized training materials and workshops"
            ],
            detailedDescription: "Technology is only as effective as the people using it. Our training and support services ensure your team can fully utilize the systems we implement. We offer comprehensive training programs, detailed documentation, and responsive support to address any issues quickly. Our goal is to make technology an enabler, not a hindrance, to your business operations.",
            caseStudy: {
                client: "West Bengal Educational Trust",
                industry: "Education",
                description: "Our training program helped 200+ faculty members transition to a new learning management system, resulting in 98% adoption rate within 3 months."
            }
        }
    ];

    const processSteps = [
        {
            number: "01",
            title: "Discovery & Analysis",
            description: "We start by understanding your business, challenges, and goals through in-depth consultations and research."
        },
        {
            number: "02",
            title: "Solution Design",
            description: "Our experts design a tailored solution architecture that addresses your specific needs and future scalability."
        },
        {
            number: "03",
            title: "Development",
            description: "Using agile methodologies, we develop your solution with regular updates and opportunities for feedback."
        },
        {
            number: "04",
            title: "Testing & Quality Assurance",
            description: "Rigorous testing ensures your solution is robust, secure, and performs optimally under all conditions."
        },
        {
            number: "05",
            title: "Deployment",
            description: "We carefully implement the solution with minimal disruption to your existing operations."
        },
        {
            number: "06",
            title: "Training & Support",
            description: "Comprehensive training and ongoing support ensure your team can maximize the value of your new solution."
        }
    ];

    const industries = ["All", "Healthcare", "Agriculture", "Logistics", "Manufacturing", "Education", "Retail"];

    return (
        <section id="services" className="py-16 md:py-24 bg-gray-50">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-16">
                    <span className="inline-block px-4 py-2 bg-purple-100 text-purple-700 rounded-full text-sm font-medium mb-4">Our Services</span>
                    <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6">Digital Solutions for Your Business</h2>
                    <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                        We provide comprehensive IT solutions to help businesses transform digitally
                        and stay ahead in today's competitive market. Our services are designed to address
                        your specific challenges and drive meaningful results.
                    </p>
                </div>

                {/* Industry Filter */}
                <div className="flex flex-wrap justify-center gap-3 mb-12">
                    {industries.map((industry) => (
                        <IndustryBadge
                            key={industry}
                            name={industry}
                            isActive={activeIndustry === industry}
                            onClick={() => setActiveIndustry(industry)}
                        />
                    ))}
                </div>

                {/* Services Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
                    {services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            features={service.features}
                            detailedDescription={service.detailedDescription}
                            caseStudy={service.caseStudy}
                            isActive={activeService === index}
                            onSelect={() => setActiveService(index)}
                        />
                    ))}
                </div>

                {/* Our Process Section */}
                <div className="bg-white rounded-xl shadow-lg p-8 md:p-12 mb-16">
                    <div className="text-center mb-12">
                        <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Our Service Delivery Process</h3>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            We follow a systematic approach to ensure high-quality results for every project
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {processSteps.map((step, index) => (
                            <ProcessStep
                                key={index}
                                number={step.number}
                                title={step.title}
                                description={step.description}
                                isActive={activeStep === index}
                                onClick={() => setActiveStep(index)}
                            />
                        ))}
                    </div>
                </div>

                {/* Technologies Section */}
                <div className="bg-purple-700 rounded-xl shadow-lg p-8 md:p-12 text-white">
                    <div className="text-center mb-12">
                        <h3 className="text-2xl md:text-3xl font-bold mb-4">Technologies We Work With</h3>
                        <p className="text-lg opacity-90 max-w-3xl mx-auto">
                            We leverage modern, robust technologies to build powerful solutions
                        </p>
                    </div>

                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
                        {technologies.map((tech, index) => (
                            <div key={index} className="bg-white bg-opacity-10 p-4 rounded-lg text-center hover:bg-opacity-20 transition-colors">
                                <div className="w-16 h-16 bg-purple-600 rounded-lg mx-auto mb-4 flex items-center justify-center">
                                    <img
                                        src={`https://www.google.com/s2/favicons?sz=64&domain_url=${tech.url}`}
                                        alt={tech.name}
                                        className="w-10 h-10"
                                    />
                                </div>
                                <p className="font-medium">{tech.name}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Contact CTA */}
                <div className="text-center mt-16">
                    <p className="text-lg text-gray-600 mb-6">
                        Looking for a custom solution tailored to your specific business needs?
                    </p>
                    <a
                        href="#contact"
                        className="px-8 py-4 bg-purple-700 text-white rounded-full font-medium hover:bg-purple-800 transition-colors inline-flex items-center"
                    >
                        Schedule a Free Consultation <ArrowRight size={20} className="ml-2" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Services;