import React, { useState, useEffect } from 'react';
import { ChevronRight, ExternalLink, ArrowRight } from 'lucide-react';
import Dashboard from '../../assets/images/hero.jpg';
import P1 from '../../assets/images/p1.png';
import P2 from '../../assets/images/p2.png';
import P3 from '../../assets/images/p3.png';

const TypingEffect = ({ words }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [typingSpeed, setTypingSpeed] = useState(150);

    useEffect(() => {
        const currentWord = words[currentWordIndex];

        const timer = setTimeout(() => {
            if (!isDeleting) {
                setDisplayedText(currentWord.substring(0, displayedText.length + 1));

                if (displayedText.length === currentWord.length) {
                    // Pause at complete word before deleting
                    setTypingSpeed(2000);
                    setIsDeleting(true);
                } else {
                    setTypingSpeed(150);
                }
            } else {
                setDisplayedText(currentWord.substring(0, displayedText.length - 1));

                if (displayedText.length === 0) {
                    setIsDeleting(false);
                    setCurrentWordIndex((currentWordIndex + 1) % words.length);
                    setTypingSpeed(500);
                } else {
                    setTypingSpeed(75);
                }
            }
        }, typingSpeed);

        return () => clearTimeout(timer);
    }, [currentWordIndex, displayedText, isDeleting, typingSpeed, words]);

    return (
        <span className="text-green-400">{displayedText}<span className="animate-pulse">|</span></span>
    );
};

const FeatureBadge = ({ text }) => (
    <div className="inline-flex items-center px-3 py-1 bg-white bg-opacity-20 text-white text-sm rounded-full mr-3 mb-3">
        <div className="w-2 h-2 bg-green-400 rounded-full mr-2"></div>
        {text}
    </div>
);

const StatCard = ({ value, label, delay }) => {
    const [count, setCount] = useState(0);
    const [inView, setInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true);
                }
            },
            { threshold: 0.1 }
        );

        const element = document.getElementById('hero-stats');
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    useEffect(() => {
        if (!inView) return;

        // Start the animation after the specified delay
        const animationDelay = setTimeout(() => {
            if (count < value) {
                const increment = Math.ceil(value / 20);
                const timer = setTimeout(() => {
                    setCount(prevCount => {
                        const newCount = prevCount + increment;
                        return newCount > value ? value : newCount;
                    });
                }, 100);

                return () => clearTimeout(timer);
            }
        }, delay);

        return () => clearTimeout(animationDelay);
    }, [count, value, inView, delay]);

    return (
        <div className="text-center">
            <div className="text-3xl md:text-4xl font-bold text-white mb-1">
                {count}<span className="text-green-400">+</span>
            </div>
            <p className="text-white text-opacity-80">{label}</p>
        </div>
    );
};

const Hero = () => {
    const typingWords = [
        "Automation",
        "Growth",
        "Efficiency",
        "Innovation",
        "Success"
    ];

    const features = [
        "Cloud Solutions",
        "Custom Software",
        "Data Analytics",
        "Mobile Apps"
    ];

    return (
        <section id="home" className="relative bg-gradient-to-br from-purple-900 via-purple-700 to-purple-600 py-20 md:py-28">
            {/* Animated Background Elements */}
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute -top-20 -right-20 w-64 h-64 bg-purple-500 rounded-full filter blur-3xl opacity-20"></div>
                <div className="absolute top-40 -left-20 w-96 h-96 bg-purple-400 rounded-full filter blur-3xl opacity-10"></div>
                <div className="absolute bottom-0 right-1/4 w-80 h-80 bg-green-400 rounded-full filter blur-3xl opacity-10"></div>

                <div className="absolute inset-0" style={{ backgroundImage: 'radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.15) 2px, transparent 0)', backgroundSize: '50px 50px' }}></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="md:w-3/5 text-white mb-16 md:mb-0 md:pr-12">
                        <div className="flex flex-wrap mb-4">
                            {features.map((feature, index) => (
                                <FeatureBadge key={index} text={feature} />
                            ))}
                        </div>

                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
                            Digital Solutions for <br />
                            <span className="relative inline-block">
                                <span className="relative z-10 mr-4">Tomorrow's </span>
                                <span className="absolute bottom-2 left-0 w-full h-3 bg-green-400 opacity-30 z-0"></span>
                            </span>
                            <TypingEffect words={typingWords} />
                        </h1>

                        <p className="text-lg md:text-xl opacity-90 mb-8 leading-relaxed max-w-xl">
                            Purpuligo Technologies helps small and medium enterprises achieve 100% digitization with our
                            innovative and affordable IT solutions tailored to your business challenges.
                        </p>

                        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-12">
                            <button className="px-6 py-3 bg-gradient-to-r from-green-500 to-green-400 text-white rounded-full font-medium hover:shadow-lg transition-all duration-300 flex items-center justify-center group">
                                Get Started
                                <ChevronRight
                                    size={20}
                                    className="ml-2 transition-transform duration-300 group-hover:translate-x-1"
                                />
                            </button>
                            <button className="px-6 py-3 border-2 border-white text-white rounded-full font-medium hover:bg-white hover:text-purple-700 transition-colors flex items-center justify-center group">
                                Watch Demo
                                <ExternalLink
                                    size={18}
                                    className="ml-2 transition-transform duration-300 group-hover:scale-110"
                                />
                            </button>
                        </div>

                        <div className="flex items-center space-x-4">
                            <a href="#" className="flex items-center">
                                <img
                                    src={P1}
                                    alt="Trusted Partner"
                                    className="w-10 h-10 rounded-full border-2 border-white"
                                    loading="lazy"
                                />
                                <img
                                    src={P2}
                                    alt="Trusted Partner"
                                    className="w-10 h-10 rounded-full border-2 border-white -ml-4"
                                    loading="lazy"
                                />
                                <img
                                    src={P3}
                                    alt="Trusted Partner"
                                    className="w-10 h-10 rounded-full border-2 border-white -ml-4"
                                    loading="lazy"
                                />
                            </a>
                            <p className="text-sm text-white opacity-90">
                                Trusted by <span className="font-bold">120+</span> businesses across India
                            </p>
                        </div>
                    </div>

                    <div className="md:w-2/5 relative">
                        {/* Dashboard Mockup */}
                        <div className="relative bg-white p-3 rounded-xl shadow-2xl transform rotate-1 hover:rotate-0 transition-transform duration-500">
                            <div className="absolute -top-4 -left-4 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded-full shadow-lg">
                                New Release
                            </div>

                            <img
                                src={Dashboard}
                                alt="Purpuligo Dashboard"
                                className="w-full h-auto rounded"
                            />

                            <div className="absolute -bottom-4 -right-4 bg-white p-3 rounded-lg shadow-lg">
                                <div className="flex items-center">
                                    <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500 mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <span className="text-sm font-medium text-gray-800">Real-time Updates</span>
                                </div>
                            </div>
                        </div>

                        {/* Floating Elements */}
                        <div className="absolute -left-6 top-1/2 transform -translate-y-1/2 bg-white p-3 rounded-lg shadow-lg">
                            <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center text-purple-700">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Stats Section */}
                <div id="hero-stats" className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-16 py-8 border-t border-white border-opacity-20">
                    <StatCard value={120} label="Happy Clients" delay={0} />
                    <StatCard value={250} label="Projects Completed" delay={200} />
                    <StatCard value={15} label="Team Members" delay={400} />
                    <StatCard value={5} label="Years of Experience" delay={600} />
                </div>
            </div>

            {/* Bottom Wave */}
            <div className="absolute bottom-0 left-0 right-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120" fill="#f9fafb">
                    <path fillOpacity="1" d="M0,96L48,85.3C96,75,192,53,288,53.3C384,53,480,75,576,80C672,85,768,75,864,69.3C960,64,1056,64,1152,69.3C1248,75,1344,85,1392,90.7L1440,96L1440,120L1392,120C1344,120,1248,120,1152,120C1056,120,960,120,864,120C768,120,672,120,576,120C480,120,384,120,288,120C192,120,96,120,48,120L0,120Z"></path>
                </svg>
            </div>
        </section>
    );
};

export default Hero;