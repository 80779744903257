/**
 * Utility functions for smooth scrolling between sections
 */

/**
 * Scrolls to the specified element with smooth animation
 * 
 * @param {string} elementId - The ID of the element to scroll to
 * @param {number} offset - Optional offset from the top (default: 80px for header height)
 * @param {number} duration - Animation duration in milliseconds (default: 800ms)
 */
export const scrollToElement = (elementId, offset = 80, duration = 800) => {
    const element = document.getElementById(elementId);
    if (!element) return;

    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    const startTime = performance.now();
    const startPosition = window.pageYOffset;

    const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;

        if (elapsedTime > duration) {
            window.scrollTo(0, offsetPosition);
            return;
        }

        const progress = easeInOutCubic(elapsedTime / duration);
        const currentPosition = startPosition + (offsetPosition - startPosition) * progress;

        window.scrollTo(0, currentPosition);
        requestAnimationFrame(animateScroll);
    };

    requestAnimationFrame(animateScroll);
};

/**
 * Easing function for smooth animation
 * Accelerates in the middle and decelerates at the end
 */
const easeInOutCubic = (t) => {
    return t < 0.5
        ? 4 * t * t * t
        : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

/**
 * Handles anchor link clicks with smooth scrolling
 * 
 * @param {Event} e - Click event
 * @param {Function} callback - Optional callback after scrolling completes
 */
export const handleSmoothScroll = (e, callback) => {
    e.preventDefault();

    const targetId = e.currentTarget.getAttribute('href').substring(1);
    scrollToElement(targetId);

    if (callback && typeof callback === 'function') {
        setTimeout(callback, 800); // Call callback after animation completes
    }
};

export default {
    scrollToElement,
    handleSmoothScroll
};