import React, { useState } from 'react';
import { MapPin, Mail, Phone, CheckCircle, Send, Clock, AlertCircle } from 'lucide-react';

const ContactInfo = ({ icon, title, info }) => {
    return (
        <div className="flex items-start bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <div className="p-3 bg-purple-100 rounded-full mr-4 text-purple-700">
                {icon}
            </div>
            <div>
                <h3 className="font-bold text-gray-800 mb-1">{title}</h3>
                <p className="text-gray-600">{info}</p>
            </div>
        </div>
    );
};

const SocialIcon = ({ href, children, label }) => {
    return (
        <a
            href={href}
            className="p-3 bg-purple-100 text-purple-700 rounded-full hover:bg-purple-700 hover:text-white transition-colors flex items-center justify-center group relative"
            aria-label={label}
        >
            {children}
            <span className="absolute top-full mt-2 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                {label}
            </span>
        </a>
    );
};

const FormField = ({ label, id, type = "text", placeholder, value, onChange, error }) => {
    return (
        <div className="mb-6">
            <label htmlFor={id} className="block text-gray-700 font-medium mb-2">{label}</label>
            {type === "textarea" ? (
                <textarea
                    id={id}
                    rows="5"
                    className={`w-full px-4 py-3 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                ></textarea>
            ) : (
                <input
                    type={type}
                    id={id}
                    className={`w-full px-4 py-3 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            )}
            {error && (
                <p className="mt-1 text-red-500 text-sm flex items-center">
                    <AlertCircle size={14} className="mr-1" /> {error}
                </p>
            )}
        </div>
    );
};

const Contact = () => {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormState(prev => ({
            ...prev,
            [id]: value
        }));

        // Clear error when user starts typing
        if (errors[id]) {
            setErrors(prev => ({
                ...prev,
                [id]: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formState.name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!formState.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
            newErrors.email = 'Email is invalid';
        }

        if (!formState.subject.trim()) {
            newErrors.subject = 'Subject is required';
        }

        if (!formState.message.trim()) {
            newErrors.message = 'Message is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);

            // Simulate API call
            setTimeout(() => {
                setIsSubmitting(false);
                setIsSubmitted(true);
                setFormState({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            }, 1500);
        }
    };

    const officeHours = [
        { day: 'Monday - Friday', hours: '9:00 AM - 6:00 PM' },
        { day: 'Saturday', hours: '10:00 AM - 4:00 PM' },
        { day: 'Sunday', hours: 'Closed' }
    ];

    return (
        <section id="contact" className="py-16 md:py-24 bg-gray-50">
            <div className="container mx-auto px-4">
                {/* Section Header */}
                <div className="text-center mb-16">
                    <span className="inline-block px-4 py-2 bg-purple-100 text-purple-700 rounded-full text-sm font-medium mb-4">Get In Touch</span>
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">Contact Purpuligo Technologies</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                        Ready to start your digital transformation journey? Reach out to us today and let's discuss how we can help your business grow.
                    </p>
                </div>

                {/* Contact Information Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                    <ContactInfo
                        icon={<MapPin size={24} />}
                        title="Visit Our Office"
                        info="College More, Raghunathpur, Jhargram, West Bengal 721507"
                    />

                    <ContactInfo
                        icon={<Mail size={24} />}
                        title="Email Us"
                        info="info@purpuligo.com"
                    />

                    <ContactInfo
                        icon={<Phone size={24} />}
                        title="Call Us"
                        info="+91 99030 53297"
                    />
                </div>

                {/* Contact Form and Map */}
                <div className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden">
                    {/* Form Section */}
                    <div className="lg:w-7/12 p-8 md:p-12">
                        <h3 className="text-2xl font-bold text-gray-800 mb-6">Send Us a Message</h3>

                        {isSubmitted ? (
                            <div className="bg-green-50 border border-green-200 rounded-lg p-6 text-center">
                                <div className="inline-flex items-center justify-center p-3 bg-green-100 text-green-500 rounded-full mb-4">
                                    <CheckCircle size={30} />
                                </div>
                                <h4 className="text-xl font-bold text-gray-800 mb-2">Message Sent Successfully!</h4>
                                <p className="text-gray-600 mb-6">
                                    Thank you for reaching out to us. Our team will get back to you within 24 hours.
                                </p>
                                <button
                                    onClick={() => setIsSubmitted(false)}
                                    className="px-6 py-3 bg-purple-700 text-white rounded-full font-medium hover:bg-purple-800 transition-colors"
                                >
                                    Send Another Message
                                </button>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <FormField
                                        label="Your Name"
                                        id="name"
                                        placeholder="Test Name"
                                        value={formState.name}
                                        onChange={handleChange}
                                        error={errors.name}
                                    />

                                    <FormField
                                        label="Your Email"
                                        id="email"
                                        type="email"
                                        placeholder="test@purpuligo.com"
                                        value={formState.email}
                                        onChange={handleChange}
                                        error={errors.email}
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <FormField
                                        label="Phone Number (Optional)"
                                        id="phone"
                                        placeholder="+91 98765 43210"
                                        value={formState.phone}
                                        onChange={handleChange}
                                    />

                                    <FormField
                                        label="Subject"
                                        id="subject"
                                        placeholder="How can we help?"
                                        value={formState.subject}
                                        onChange={handleChange}
                                        error={errors.subject}
                                    />
                                </div>

                                <FormField
                                    label="Message"
                                    id="message"
                                    type="textarea"
                                    placeholder="Tell us about your project or inquiry..."
                                    value={formState.message}
                                    onChange={handleChange}
                                    error={errors.message}
                                />

                                <button
                                    type="submit"
                                    className="w-full px-6 py-3 bg-purple-700 text-white rounded-full font-medium hover:bg-purple-800 transition-colors flex items-center justify-center"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Sending...
                                        </>
                                    ) : (
                                        <>
                                            Send Message <Send size={20} className="ml-2" />
                                        </>
                                    )}
                                </button>
                            </form>
                        )}
                    </div>

                    {/* Map and Additional Info */}
                    <div className="lg:w-5/12 bg-gray-100">
                        <div className="h-64 lg:h-full bg-gray-300 relative">
                            {/* <img
                                src="/api/placeholder/800/600"
                                alt="Office Location Map"
                                className="w-full h-full object-cover"
                            /> */}
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d921.904351128648!2d86.99374560266735!3d22.44342171467512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d7a322b4e97af%3A0x7bed6255ab6b53b4!2sPurpuligo%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1742623369775!5m2!1sen!2sin"
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                            {/* <div className="absolute inset-0 flex items-center justify-center">
                                <span className="px-4 py-2 bg-white rounded-lg shadow-md text-gray-700 font-medium">
                                    Interactive Map Coming Soon
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Additional Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
                    {/* Office Hours */}
                    <div className="bg-white p-8 rounded-lg shadow-md">
                        <div className="flex items-center mb-6">
                            <Clock size={24} className="text-purple-700 mr-3" />
                            <h3 className="text-xl font-bold text-gray-800">Office Hours</h3>
                        </div>
                        <div className="space-y-4">
                            {officeHours.map((item, index) => (
                                <div key={index} className="flex justify-between items-center py-2 border-b border-gray-100">
                                    <span className="font-medium text-gray-700">{item.day}</span>
                                    <span className="text-gray-600">{item.hours}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Social Connections */}
                    <div className="bg-white p-8 rounded-lg shadow-md">
                        <h3 className="text-xl font-bold text-gray-800 mb-6">Connect With Us</h3>
                        <p className="text-gray-600 mb-6">
                            Follow us on social media to stay updated with our latest news, projects, and technology insights.
                        </p>
                        <div className="flex space-x-4">
                            <SocialIcon href="#" label="Facebook">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                            </SocialIcon>
                            <SocialIcon href="#" label="Instagram">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line></svg>
                            </SocialIcon>
                            <SocialIcon href="#" label="LinkedIn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                            </SocialIcon>
                            <SocialIcon href="#" label="Twitter">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                            </SocialIcon>
                            <SocialIcon href="#" label="YouTube">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                            </SocialIcon>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;