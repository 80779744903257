import React, { useState, useEffect } from 'react';
import { scrollToElement, handleSmoothScroll } from '../../utils/scrollUtils';
import { ChevronUp } from 'lucide-react';

const Navigation = () => {
    const [activeSection, setActiveSection] = useState('home');
    const [isScrolled, setIsScrolled] = useState(false);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [sectionPositions, setSectionPositions] = useState({});

    // Sections to track
    const sections = [
        { id: 'home', label: 'Home' },
        { id: 'services', label: 'Services' },
        { id: 'about', label: 'About Us' },
        { id: 'domains', label: 'Domains' },
        { id: 'team', label: 'Our Team' },
        { id: 'contact', label: 'Contact' }
    ];

    // Detect scroll position for sticky header and scroll-to-top button
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            // Sticky header
            setIsScrolled(scrollPosition > 50);

            // Show scroll-to-top button after scrolling down 500px
            setShowScrollTop(scrollPosition > 500);

            // Set active section based on scroll position
            const currentPosition = window.scrollY + 200; // 200px offset for better UX

            // Find the section that is currently in view
            let activeSection = 'home';
            Object.entries(sectionPositions).forEach(([id, position]) => {
                if (currentPosition >= position) {
                    activeSection = id;
                }
            });

            setActiveSection(activeSection);
        };

        // Calculate section positions
        const calculateSectionPositions = () => {
            const positions = {};

            sections.forEach(section => {
                const element = document.getElementById(section.id);
                if (element) {
                    positions[section.id] = element.offsetTop;
                }
            });

            setSectionPositions(positions);
        };

        // Initial calculation
        calculateSectionPositions();

        // Update on scroll
        window.addEventListener('scroll', handleScroll);

        // Recalculate on resize
        window.addEventListener('resize', calculateSectionPositions);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', calculateSectionPositions);
        };
    }, [sectionPositions]);

    // Handle section navigation
    const navigateToSection = (e, sectionId) => {
        e.preventDefault();
        scrollToElement(sectionId);
    };

    // Scroll to top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {/* Side Navigation Dots */}
            <div className="fixed right-6 top-1/2 transform -translate-y-1/2 z-40 hidden md:block">
                <div className="flex flex-col items-center space-y-6">
                    {sections.map(section => (
                        <button
                            key={section.id}
                            onClick={(e) => navigateToSection(e, section.id)}
                            className={`relative group flex items-center`}
                            aria-label={`Navigate to ${section.label}`}
                        >
                            <span
                                className={`w-3 h-3 rounded-full transition-all duration-300 ${activeSection === section.id
                                        ? 'bg-purple-700 scale-125'
                                        : 'bg-gray-300 hover:bg-purple-400'
                                    }`}
                            />
                            <span className="absolute right-7 whitespace-nowrap px-2 py-1 bg-purple-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                                {section.label}
                            </span>
                        </button>
                    ))}
                </div>
            </div>

            {/* Scroll to Top Button */}
            <button
                onClick={scrollToTop}
                className={`fixed right-6 bottom-6 p-3 bg-purple-700 text-white rounded-full shadow-lg z-40 transition-all duration-300 ${showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
                    }`}
                aria-label="Scroll to top"
            >
                <ChevronUp size={24} />
            </button>

            {/* Section Progress Indicator */}
            <div className="fixed top-0 left-0 w-full h-1 z-50">
                <div
                    className="h-full bg-green-500 transition-all duration-300"
                    style={{
                        width: `${Math.min(
                            (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100,
                            100
                        )}%`
                    }}
                />
            </div>
        </>
    );
};

export default Navigation;