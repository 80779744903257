import React, { useEffect, useState } from 'react';
import '../../assets/styles/scrollSnap.css';

const ScrollManager = ({ children }) => {
    const [isEnabled, setIsEnabled] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Check if we're on mobile
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIfMobile();

        // Add resize listener
        window.addEventListener('resize', checkIfMobile);

        // Clean up on unmount
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    useEffect(() => {
        // Only enable scroll snapping on desktop
        if (isMobile || !isEnabled) return;

        // Set up navigation handlers

        // Add class to body for styling
        document.body.classList.add('scroll-snap-container');

        // Clean up on unmount
        return () => {
            document.body.classList.remove('scroll-snap-container');
        };
    }, [isEnabled, isMobile]);

    // Toggle scroll snapping
    const toggleScrollSnap = () => {
        setIsEnabled(!isEnabled);
    };

    return (
        <div className="scroll-manager">
            {/* Control button */}
            {/* <button
                className={`fixed left-6 bottom-6 p-2 rounded-full z-50 transition-colors ${isEnabled ? 'bg-purple-700 text-white' : 'bg-gray-300 text-gray-700'
                    }`}
                onClick={toggleScrollSnap}
                // title={isEnabled ? 'Disable section scrolling' : 'Enable section scrolling'}
            >
                {isEnabled ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="7 13 12 18 17 13"></polyline>
                        <polyline points="7 6 12 11 17 6"></polyline>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                )}
            </button> */}

            {children}
        </div>
    );
};

export default ScrollManager;