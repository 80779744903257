import React from 'react';
import { MapPin, Mail, Phone } from 'lucide-react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-2xl font-bold mb-6">
                            Purpuligo<span className="text-green-400">Tech</span>
                        </h3>
                        <p className="opacity-75 mb-6">
                            Empowering small and medium enterprises through digital transformation.
                        </p>
                        <div className="flex space-x-4">
                            <a href="#" className="hover:text-green-400 transition-colors">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                            </a>
                            <a href="#" className="hover:text-green-400 transition-colors">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="2" y="2" width="16" height="16" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line></svg>
                            </a>
                            <a href="#" className="hover:text-green-400 transition-colors">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                            </a>
                            <a href="#" className="hover:text-green-400 transition-colors">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                            </a>
                        </div>
                    </div>

                    <div>
                        <h4 className="text-lg font-bold mb-4">Quick Links</h4>
                        <ul className="space-y-2">
                            <li><a href="#home" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Home</a></li>
                            <li><a href="#services" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Services</a></li>
                            <li><a href="#about" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">About Us</a></li>
                            <li><a href="#domains" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Domains</a></li>
                            <li><a href="#team" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Our Team</a></li>
                            <li><a href="#contact" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Contact</a></li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="text-lg font-bold mb-4">Our Services</h4>
                        <ul className="space-y-2">
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Custom Software</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Web Development</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Digital Transformation</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">IT Consulting</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Enterprise Solutions</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">IT Training & Support</a></li>
                        </ul>
                    </div>

                    <div>
                        <h4 className="text-lg font-bold mb-4">Contact Info</h4>
                        <ul className="space-y-4">
                            <li className="flex items-start">
                                <MapPin size={20} className="text-green-400 mr-3 mt-1 flex-shrink-0" />
                                <span className="opacity-75">College More, Raghunathpur, Jhargram, West Bengal 721507</span>
                            </li>
                            <li className="flex items-start">
                                <Mail size={20} className="text-green-400 mr-3 mt-1 flex-shrink-0" />
                                <span className="opacity-75">info@purpuligo.com</span>
                            </li>
                            <li className="flex items-start">
                                <Phone size={20} className="text-green-400 mr-3 mt-1 flex-shrink-0" />
                                <span className="opacity-75">+91 99030 53297</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
                    <p className="opacity-75 mb-4 md:mb-0">© 2025 Purpuligo Technologies. All rights reserved.</p>
                    <div>
                        <ul className="flex space-x-6">
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Privacy Policy</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Terms of Service</a></li>
                            <li><a href="#" className="opacity-75 hover:opacity-100 hover:text-green-400 transition-colors">Sitemap</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;