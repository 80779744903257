import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { handleSmoothScroll } from '../../utils/scrollUtils';
import Logo from '../../assets/images/logo.png'

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSection, setActiveSection] = useState('home');
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Update header style when scrolled
            setIsScrolled(window.scrollY > 50);

            // Update active section based on scroll position
            const sections = document.querySelectorAll('section[id]');
            let current = '';

            sections.forEach(section => {
                const sectionTop = section.offsetTop - 100;
                if (window.scrollY >= sectionTop) {
                    current = section.getAttribute('id');
                }
            });

            if (current && current !== activeSection) {
                setActiveSection(current);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeSection]);

    // Navigation Items
    const navItems = [
        { id: 'home', label: 'Home' },
        { id: 'services', label: 'Services' },
        { id: 'about', label: 'About Us' },
        { id: 'domains', label: 'Domains' },
        { id: 'team', label: 'Our Team' },
        { id: 'contact', label: 'Contact' }
    ];

    return (
        <header className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled
            ? 'bg-white shadow-md py-2'
            : 'bg-transparent py-4'
            }`}>
            <div className="container mx-auto px-4">
                <nav className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="mr-6">
                            <img src={Logo} alt="Purpuligo Tech Logo"
                                loading="lazy" className={`h-10 transition-all duration-300 ${isScrolled ? 'h-10' : 'h-12'}`} />
                        </div>

                        <ul className="hidden md:flex space-x-8">
                            {navItems.map(item => (
                                <li key={item.id}>
                                    <a
                                        href={`#${item.id}`}
                                        onClick={(e) => handleSmoothScroll(e, () => {
                                            // Optional callback after scrolling completes
                                        })}
                                        className={`font-medium transition-colors duration-300 ${activeSection === item.id
                                            ? isScrolled ? 'text-purple-700' : 'text-green-400'
                                            : isScrolled ? 'text-gray-700 hover:text-purple-700' : 'text-white hover:text-green-400'
                                            }`}
                                    >
                                        {item.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="hidden md:flex items-center space-x-4">
                        <button
                            className={`px-5 py-2 rounded-full font-medium transition-colors duration-300 ${isScrolled
                                ? 'bg-green-500 text-white hover:bg-green-600'
                                : 'bg-green-500 text-white hover:bg-green-400'
                                }`}
                            onClick={(e) => handleSmoothScroll(e, null, 'contact')}
                        >
                            Get Started
                        </button>
                        <button
                            className={`px-5 py-2 rounded-full font-medium transition-colors duration-300 ${isScrolled
                                ? 'border border-purple-700 text-purple-700 hover:bg-purple-700 hover:text-white'
                                : 'border-2 border-white text-white hover:bg-white hover:text-purple-700'
                                }`}
                            onClick={(e) => handleSmoothScroll(e, null, 'contact')}
                        >
                            Contact Us
                        </button>
                    </div>
                    <button
                        className={`md:hidden transition-colors ${isScrolled ? 'text-gray-700' : 'text-white'
                            }`}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </nav>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="md:hidden bg-white shadow-lg">
                    <ul className="py-4 px-6 space-y-4">
                        {navItems.map(item => (
                            <li key={item.id}>
                                <a
                                    href={`#${item.id}`}
                                    className={`block font-medium ${activeSection === item.id ? 'text-purple-700' : 'text-gray-700'}`}
                                    onClick={(e) => {
                                        handleSmoothScroll(e, () => setIsMenuOpen(false));
                                    }}
                                >
                                    {item.label}
                                </a>
                            </li>
                        ))}
                        <li className="pt-4">
                            <button className="w-full px-5 py-2 bg-green-500 text-white rounded-full font-medium hover:bg-green-600 transition-colors"
                                onClick={() => {
                                    setIsMenuOpen(false);
                                    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Get Started
                            </button>
                        </li>
                        <li>
                            <button className="w-full px-5 py-2 border border-purple-700 text-purple-700 rounded-full font-medium hover:bg-purple-700 hover:text-white transition-colors"
                                onClick={() => {
                                    setIsMenuOpen(false);
                                    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                Contact Us
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </header>
    );
};

export default Header;