import React, { useState } from 'react';
import { CheckCircle, Award, Users, TrendingUp, Clock, MapPin, ArrowRight } from 'lucide-react';
import AbImg from '../../assets/images/about.png';
import Award1 from '../../assets/images/award1.png';
import Award2 from '../../assets/images/award2.png';
import Award3 from '../../assets/images/award3.png';
import Award4 from '../../assets/images/award4.png';
import Team1 from '../../assets/images/team.png';

const StatItem = ({ icon, value, label }) => (
    <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
        <div className="p-3 bg-purple-100 text-purple-700 rounded-full mb-4">
            {icon}
        </div>
        <h3 className="text-3xl font-bold text-gray-800 mb-1">{value}</h3>
        <p className="text-gray-600 text-center">{label}</p>
    </div>
);

const MilestoneItem = ({ year, title, description }) => {
    return (
        <div className="flex">
            <div className="flex flex-col items-center mr-4">
                <div className="p-2 w-10 h-10 rounded-full bg-purple-700 text-white flex items-center justify-center text-sm font-bold">
                    {year}
                </div>
                <div className="h-full w-0.5 bg-purple-200 mt-2"></div>
            </div>
            <div className="pb-8">
                <h3 className="text-lg font-bold text-gray-800 mb-2">{title}</h3>
                <p className="text-gray-600">{description}</p>
            </div>
        </div>
    );
};

const About = () => {
    const [activeTab, setActiveTab] = useState('mission');

    const stats = [
        { icon: <Users size={24} />, value: "25+", label: "Talented Professionals" },
        { icon: <Award size={24} />, value: "120+", label: "Projects Completed" },
        { icon: <TrendingUp size={24} />, value: "85%", label: "Businesses Grew" },
        { icon: <Clock size={24} />, value: "5+", label: "Years of Experience" }
    ];

    const milestones = [
        {
            year: "2020",
            title: "Purpuligo Technologies Founded",
            description: "Established with a mission to bring digital revolution to small and medium businesses in West Bengal."
        },
        {
            year: "2021",
            title: "First Major Client Partnership",
            description: "Partnered with a leading agricultural company to develop farm management solutions."
        },
        {
            year: "2022",
            title: "Team Expansion",
            description: "Grew to a team of 15 professionals specializing in various IT domains."
        },
        {
            year: "2023",
            title: "Healthcare Solutions Launch",
            description: "Expanded service offerings to include specialized healthcare IT solutions."
        },
        {
            year: "2024",
            title: "Regional Recognition",
            description: "Recognized as one of the fastest-growing IT startups in Eastern India."
        }
    ];

    return (
        <section id="about" className="py-16 md:py-24">
            <div className="container mx-auto px-4">
                {/* Introduction */}
                <div className="relative mb-20">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-3/4 h-1/2 bg-purple-50 rounded-full filter blur-3xl opacity-30"></div>
                    </div>

                    <div className="relative text-center">
                        <span className="inline-block px-4 py-2 bg-purple-100 text-purple-700 rounded-full text-sm font-medium mb-4">About Purpuligo</span>
                        <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6">Pioneering Digital Transformation</h2>
                        <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
                            From a small town in West Bengal to becoming a driving force in digital transformation,
                            Purpuligo Technologies is reshaping how small and medium enterprises leverage technology.
                        </p>

                        <div className="mt-10 flex flex-wrap justify-center gap-6">
                            <div className="flex items-center p-4 bg-white rounded-lg shadow-md">
                                <span className="text-3xl font-bold text-purple-700 mr-3">5+</span>
                                <span className="text-gray-700">Years of<br />Excellence</span>
                            </div>
                            <div className="flex items-center p-4 bg-white rounded-lg shadow-md">
                                <span className="text-3xl font-bold text-purple-700 mr-3">120+</span>
                                <span className="text-gray-700">Projects<br />Delivered</span>
                            </div>
                            <div className="flex items-center p-4 bg-white rounded-lg shadow-md">
                                <span className="text-3xl font-bold text-purple-700 mr-3">85%</span>
                                <span className="text-gray-700">Client<br />Growth Rate</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <div className="flex flex-col md:flex-row items-center md:items-start mb-20">
                    <div className="md:w-1/2 mb-10 md:mb-0 md:pr-12">
                        <div className="relative">
                            <img
                                src={AbImg}
                                alt="About Purpuligo Technologies"
                                className="w-full h-auto rounded-lg shadow-lg z-10 relative"
                                loading="lazy"
                            />
                            <div className="absolute -bottom-6 -right-6 w-2/3 h-2/3 bg-purple-100 rounded-lg -z-10"></div>

                            <div className="absolute top-4 left-4 bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <MapPin size={20} className="text-purple-700 mr-2" />
                                    <span className="text-gray-700 font-medium">West Bengal, India</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="md:w-1/2">
                        {/* Tabs Navigation */}
                        <div className="flex mb-8 border-b border-gray-200">
                            <button
                                className={`pb-4 px-4 font-medium ${activeTab === 'mission' ? 'text-purple-700 border-b-2 border-purple-700' : 'text-gray-500 hover:text-purple-700'}`}
                                onClick={() => setActiveTab('mission')}
                            >
                                Our Mission
                            </button>
                            <button
                                className={`pb-4 px-4 font-medium ${activeTab === 'story' ? 'text-purple-700 border-b-2 border-purple-700' : 'text-gray-500 hover:text-purple-700'}`}
                                onClick={() => setActiveTab('story')}
                            >
                                Our Story
                            </button>
                            <button
                                className={`pb-4 px-4 font-medium ${activeTab === 'approach' ? 'text-purple-700 border-b-2 border-purple-700' : 'text-gray-500 hover:text-purple-700'}`}
                                onClick={() => setActiveTab('approach')}
                            >
                                Our Approach
                            </button>
                        </div>

                        {/* Tab Content */}
                        <div className="tab-content">
                            {activeTab === 'mission' && (
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Our Mission & Vision</h3>
                                    <p className="text-lg text-gray-600 mb-6">
                                        Purpuligo Technologies is on a mission to help small and medium enterprises achieve 100% digitization,
                                        bringing the power of technology to businesses that need it most.
                                    </p>
                                    <p className="text-lg text-gray-600 mb-8">
                                        We envision a future where every business, regardless of size or location,
                                        has access to transformative digital solutions that drive growth and innovation.
                                    </p>
                                    <div className="space-y-4">
                                        <div className="flex items-start">
                                            <CheckCircle size={24} className="text-green-500 mr-3 mt-1 flex-shrink-0" />
                                            <p className="text-gray-700">Bringing digital transformation to underserved markets</p>
                                        </div>
                                        <div className="flex items-start">
                                            <CheckCircle size={24} className="text-green-500 mr-3 mt-1 flex-shrink-0" />
                                            <p className="text-gray-700">Making enterprise-grade technology accessible to all businesses</p>
                                        </div>
                                        <div className="flex items-start">
                                            <CheckCircle size={24} className="text-green-500 mr-3 mt-1 flex-shrink-0" />
                                            <p className="text-gray-700">Creating solutions that respect local business practices while modernizing operations</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === 'story' && (
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Our Journey</h3>
                                    <p className="text-lg text-gray-600 mb-8">
                                        Founded in 2020, Purpuligo Technologies started with a small team of passionate technologists
                                        who believed in the power of digital transformation to revolutionize local businesses.
                                    </p>

                                    <div className="space-y-0 mt-8">
                                        {milestones.map((milestone, index) => (
                                            <MilestoneItem
                                                key={index}
                                                year={milestone.year}
                                                title={milestone.title}
                                                description={milestone.description}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                            {activeTab === 'approach' && (
                                <div>
                                    <h3 className="text-2xl font-bold text-gray-800 mb-4">Our Approach to Innovation</h3>
                                    <p className="text-lg text-gray-600 mb-6">
                                        At Purpuligo Technologies, we believe that effective digital transformation requires more than just technology –
                                        it needs a deep understanding of business processes and human factors.
                                    </p>
                                    <p className="text-lg text-gray-600 mb-8">
                                        Our approach combines technical expertise with business acumen, ensuring solutions that are
                                        not only technologically sound but also practical and impactful.
                                    </p>

                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                                        <div className="bg-purple-50 p-4 rounded-lg">
                                            <h4 className="font-bold text-gray-800 mb-2">Client-Centric Focus</h4>
                                            <p className="text-gray-600">We build solutions based on deep understanding of our clients' specific needs</p>
                                        </div>
                                        <div className="bg-purple-50 p-4 rounded-lg">
                                            <h4 className="font-bold text-gray-800 mb-2">Agile Development</h4>
                                            <p className="text-gray-600">Iterative processes that ensure adaptability and continuous improvement</p>
                                        </div>
                                        <div className="bg-purple-50 p-4 rounded-lg">
                                            <h4 className="font-bold text-gray-800 mb-2">Technology Excellence</h4>
                                            <p className="text-gray-600">Commitment to using the most appropriate and effective technologies</p>
                                        </div>
                                        <div className="bg-purple-50 p-4 rounded-lg">
                                            <h4 className="font-bold text-gray-800 mb-2">Knowledge Transfer</h4>
                                            <p className="text-gray-600">Ensuring clients can independently maintain and grow with their systems</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Our Values Section */}
                <div className="mb-20">
                    <div className="text-center mb-12">
                        <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Our Core Values</h3>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            These principles guide everything we do at Purpuligo Technologies
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="bg-white p-8 rounded-lg shadow-md border-t-4 border-purple-700 hover:shadow-lg transition-shadow">
                            <h4 className="text-xl font-bold text-gray-800 mb-4">Innovation</h4>
                            <p className="text-gray-600">
                                We constantly explore new technologies and methodologies to deliver cutting-edge solutions that give our clients a competitive advantage.
                            </p>
                        </div>

                        <div className="bg-white p-8 rounded-lg shadow-md border-t-4 border-green-500 hover:shadow-lg transition-shadow">
                            <h4 className="text-xl font-bold text-gray-800 mb-4">Integrity</h4>
                            <p className="text-gray-600">
                                We believe in transparent processes, honest communication, and delivering on our promises to build lasting relationships with our clients.
                            </p>
                        </div>

                        <div className="bg-white p-8 rounded-lg shadow-md border-t-4 border-blue-500 hover:shadow-lg transition-shadow">
                            <h4 className="text-xl font-bold text-gray-800 mb-4">Impact</h4>
                            <p className="text-gray-600">
                                Our success is measured by the tangible business outcomes and value we create for our clients through digital transformation.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Recognition Section */}
                <div className="mb-20">
                    <div className="text-center mb-12">
                        <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Recognition & Achievements</h3>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            Our commitment to excellence has earned us recognition in the technology space
                        </p>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg hover:-translate-y-1 transition-all">
                            <img src={Award1} alt="Award" className="w-16 h-16 mx-auto mb-4"
                                loading="lazy" />
                            <h4 className="font-bold text-gray-800">Best Tech Startup 2023</h4>
                            <p className="text-gray-600 text-sm">Eastern India Tech Awards</p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg hover:-translate-y-1 transition-all">
                            <img src={Award2} alt="Award" className="w-16 h-16 mx-auto mb-4"
                                loading="lazy" />
                            <h4 className="font-bold text-gray-800">Excellence in Innovation</h4>
                            <p className="text-gray-600 text-sm">Bengal Chamber of Commerce</p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg hover:-translate-y-1 transition-all">
                            <img src={Award3} alt="Award" className="w-16 h-16 mx-auto mb-4"
                                loading="lazy" />
                            <h4 className="font-bold text-gray-800">Top 50 Emerging Companies</h4>
                            <p className="text-gray-600 text-sm">TechSpark India 2024</p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg hover:-translate-y-1 transition-all">
                            <img src={Award4} alt="Award" className="w-16 h-16 mx-auto mb-4"
                                loading="lazy" />
                            <h4 className="font-bold text-gray-800">Digital Inclusion Award</h4>
                            <p className="text-gray-600 text-sm">Rural Tech Foundation</p>
                        </div>
                    </div>
                </div>

                {/* Team Preview */}
                <div className="bg-gradient-to-r from-purple-700 to-purple-500 rounded-lg shadow-xl overflow-hidden mb-8">
                    <div className="relative flex flex-col md:flex-row">
                        <div className="md:w-2/3 p-8 md:p-12">
                            <h3 className="text-2xl md:text-3xl font-bold text-white mb-4">Led by Experts, Driven by Passion</h3>
                            <p className="text-lg text-purple-100 mb-8">
                                Our diverse team combines industry experience with technological expertise to deliver solutions that truly transform businesses. We're committed to innovation, quality, and client success.
                            </p>
                            <a
                                href="#team"
                                className="px-8 py-3 bg-white text-purple-700 rounded-full font-medium hover:bg-purple-50 transition-colors inline-flex items-center"
                            >
                                Meet Our Team <ArrowRight size={18} className="ml-2" />
                            </a>
                        </div>
                        <div className="md:w-1/3 relative">
                            <div className="absolute inset-0 bg-gradient-to-l from-transparent to-purple-700 md:from-transparent md:to-transparent z-10 md:hidden"></div>
                            <img
                                src={Team1}
                                alt="Purpuligo Team"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>

                    <div className="px-8 py-6 bg-purple-800 flex flex-wrap justify-center md:justify-between items-center gap-6">
                        <div className="flex items-center">
                            <div className="mr-4">
                                <span className="text-3xl font-bold text-white">25+</span>
                            </div>
                            <div className="text-purple-100">
                                <p>Talented</p>
                                <p>Professionals</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="mr-4">
                                <span className="text-3xl font-bold text-white">10+</span>
                            </div>
                            <div className="text-purple-100">
                                <p>Technology</p>
                                <p>Specializations</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="mr-4">
                                <span className="text-3xl font-bold text-white">90%</span>
                            </div>
                            <div className="text-purple-100">
                                <p>Client</p>
                                <p>Retention</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;