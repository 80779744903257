import React from 'react';
import { ArrowRight } from 'lucide-react';

const DomainCard = ({ title, description }) => {
    return (
        <div className="bg-purple-800 bg-opacity-50 p-8 rounded-lg hover:bg-opacity-70 transition-colors">
            <h3 className="text-xl font-bold mb-4">{title}</h3>
            <p className="opacity-90 mb-6">{description}</p>
            <a href="#" className="text-green-400 font-medium inline-flex items-center hover:underline">
                Explore solutions <ArrowRight size={16} className="ml-2" />
            </a>
        </div>
    );
};

const Domains = () => {
    const domains = [
        {
            title: "Agriculture",
            description: "Digital solutions for farm management, crop monitoring, supply chain optimization, and smart agriculture practices."
        },
        {
            title: "Healthcare",
            description: "Innovative IT systems for patient management, telemedicine, medical records, and healthcare administration."
        },
        {
            title: "Logistics",
            description: "Digital tracking, inventory management, route optimization, and supply chain solutions for logistics companies."
        },
        {
            title: "Construction",
            description: "Project management tools, resource planning, safety monitoring, and BIM integration for construction businesses."
        },
        {
            title: "Mining",
            description: "Safety systems, operational monitoring, resource management, and process automation for the mining industry."
        },
        {
            title: "Retail & E-commerce",
            description: "Online store development, inventory management, customer relationship, and point-of-sale solutions."
        }
    ];

    return (
        <section id="domains" className="py-16 md:py-24 bg-purple-700 text-white">
            <div className="container mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">Industries We Serve</h2>
                    <p className="text-lg opacity-90 max-w-2xl mx-auto">
                        Our expertise spans across multiple domains, bringing digital transformation to various industries.
                    </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {domains.map((domain, index) => (
                        <DomainCard
                            key={index}
                            title={domain.title}
                            description={domain.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Domains;